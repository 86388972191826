<template>
	<b-modal id="edit-connection" size="lg" title="Edit Connection" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<!-- Select Connection -->
				<b-row class="my-2">
					<b-col sm="8">
						<b>SELECT CONNECTION</b>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-form-group label="Source" label-for="fromCompany" description>
							<b-form-input name="Source" type="text" v-model="form.company.name" readonly />
						</b-form-group>

						<b-card :title="selFromCompany.name" v-show="selFromCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selFromCompany.type }}
								<br />
								<b>Industry:</b>
								{{ selFromCompany.industry }}
								<br />
								<b>Address:</b>
								{{ selFromCompany.address }}
								<br />

								<span v-if="selFromCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-form-group label="Destination" label-for="toCompany" description>
							<b-form-input name="Destination" type="text" v-model="form.connectedCompany.name" readonly />
						</b-form-group>

						<b-card :title="selToCompany.name" v-show="selToCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selToCompany.type }}
								<br />
								<b>Industry:</b>
								{{ selToCompany.industry }}
								<br />
								<b>Address:</b>
								{{ selToCompany.address }}
								<br />
								<span v-if="selToCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
				</b-row>

				<!-- Select Locations -->
				<b-row class="mt-4">
					<b-col sm="6" md="6">
						<span class="details-view-title">SELECT LOCATIONS</span>
						<div class="details-view-subtitle">List of destination company storage locations</div>
					</b-col>
					<b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>
				<b-table show-empty striped hover :items="storageLocationItems" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>	
					<template v-slot:cell(selection)="row">
						<b-form-checkbox :id="row.item.id"
							v-model="storageLocationsObj[row.item.id]['isIncluded']" value="true"
							unchecked-value="false" />
					</template>
					<template v-slot:cell(geoaddress)="row">
						<span class="numFont">
							({{ row.item.geoaddress.latitude }}, {{ row.item.geoaddress.longitude }})
						</span>
					</template>
				</b-table>
				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>

				<!-- Other Details -->
				<b-row class="mt-4 mb-2">
					<b-col sm="8">
						<b>OTHER DETAILS</b>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Connection Types">
							<b-form-text class="connection-types">Select at least 1 connection type</b-form-text>
							<b-form-checkbox-group stacked id="connectionTypes" name="Connection Types" v-model="form.connections">
								<b-form-checkbox v-bind:key="conType.id" :value="conType.value"
									v-for="conType in connectionTypes">{{ conType.text }}
								</b-form-checkbox>
							</b-form-checkbox-group>
							<b-form-group v-show="showOthersField" label="If Others, please specify" label-for="others" class="mt-2" >
								<b-form-input name="Others" v-model="form.others" type="text" v-validate="{
									required: showOthersField,
								}" placeholder="Please specify"></b-form-input>
								<span v-show="errors.has('Others')" class="help-block">{{
									errors.first('Others')
								}}</span>
							</b-form-group>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Notes">
							<b-form-textarea id="notes" name="Notes" v-model="form.notes" placeholder="Other info here"
								:rows="2" :max-rows="6" maxlength="200" v-validate="{
									required: true,
									regex: remarksRegex
								}" />
							<span v-show="errors.has('Notes')" class="help-block">{{
								errors.first('Notes')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ConnectionUtil } from '@/utils/connectionUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & Database
import connectionsDAO from '@/database/connections';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-connection',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allConnectedCompaniesObj: {
			type: Object,
			required: true
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		connectionTypes: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			fields: [
				{
					key: 'selection',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					sortable: true,
				},
				{
					key: 'address',
					sortable: false,
				},
				'geoaddress',
			],
			currentPage: 1,
            perPage: 5,
			totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
			
			form: { ...ConnectionUtil.getDefaultConnectionObj() },
			selFromCompany: config.companyDefaultValue,
			selToCompany: config.companyDefaultValue,
			storageLocationItems: [],
			storageLocationsObj: {},
			showOthersField: false,

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		'form.connections': function () {
			if (this.form.connections && this.form.connections.includes('Others')) {
				this.showOthersField = true;
			} else {
				this.showOthersField = false;
			}
		}
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			}

			let existsConnection = await this.hasExistingConnection();
			if (existsConnection && !this.isOrigConnectionLoaded()) {
				this.$toaster.warning('There is already an existing connection between this two companies.');
				this.isLoading = false;
				return;
			}
			// At least 1 connection required
			else if (this.form.connections.length <= 0) {
				this.$toaster.warning('You should have at least 1 connection type between the selected companies.');
				this.isLoading = false;
				return;
			}
			// if Others is selected, the others field is required
			else if (
				this.form.connections.includes('Others') &&
				this.form.others.length <= 0
			) {
				this.$toaster.warning('Please fill-in the Others field to proceed.');
				this.isLoading = false;
				return;
			}
			// At least 1 storage location must be connected
			else if (!this.hasAtLeastOneStorageLocation()) {
				this.$toaster.warning('Please select at least 1 storage location to connect.');
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		hasAtLeastOneStorageLocation() {
			let hasStorageLocation = false;
			let locArr = Object.values(this.storageLocationsObj);
			for (const loc of locArr) {
				if (loc.isIncluded === 'true') {
					hasStorageLocation = true;
					break;
				}
			}
			return hasStorageLocation;
		},
		async hasExistingConnection() {
			let status = false;
			if (this.selFromCompany.id && this.selToCompany.id) {
				let connectionObj = await connectionsDAO.getConnection(
					this.selFromCompany.id,
					this.selToCompany.id
				);

				return !_.isEmpty(connectionObj);
			}
			return status;
		},
		isOrigConnectionLoaded() {
			let status = false;
			if (
				this.selFromCompany.name === this.form.company.name &&
				this.selToCompany.name === this.form.connectedCompany.name
			) {
				status = true;
			}
			return status;
		},

		getParam() {
			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);
			this.form.others = ValidationUtil.removeExcessWhiteSpace(this.form.others);

			// update selection
			this.updateFromCompany();
			this.updateToCompany();
			this.form.storageLocations = this.storageLocationsObj;

			return this.form;
		},
		updateFromCompany() {
			this.form.company = this.getCompany(this.selFromCompany.id);
			this.form.company.geoaddress = LocationUtil.getGeoaddress(this.form.company.geoaddress);
			this.form.companyId = this.selFromCompany.id;
		},
		updateToCompany() {
			this.form.connectedCompany = this.getCompany(this.selToCompany.id);
			this.form.connectedCompany.geoaddress = LocationUtil.getGeoaddress(this.form.connectedCompany.geoaddress);
			this.form.connectedCompanyId = this.selToCompany.id;
		},
		getCompany(id) {
			if (this.allCompaniesObj[id]) {
				return this.allCompaniesObj[id];
			} else if (this.allConnectedCompaniesObj[id]) {
				return this.allConnectedCompaniesObj[id];
			}
			return { ...config.companyDefaultValue };
		},
		
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				const result = await this.$store.dispatch('updateConnection', this.getParam());

				if (result.isSuccess) {
					this.$toaster.success(`Connection between "${this.selFromCompany.name}" and "${this.selToCompany.name}" was updated successfully.`);
					EventBus.$emit('onCloseSaveConnection', result.data);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error updating connection between "${this.selFromCompany.name}" and "${this.selToCompany.name}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error updating connection between "${this.selFromCompany.name}" and "${this.selToCompany.name}". Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...this.$store.getters.currConnection };

			this.selFromCompany = this.form.company;
			this.selToCompany = this.form.connectedCompany;
			this.retrieveStorageLocations(this.selToCompany);

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
		retrieveStorageLocations(selCompany) {
			// reset values
			this.storageLocationItems = [];
			this.storageLocationsObj = {};

			let storageLocations = _.filter(this.allStorageLocationsObj, o => {
				return o.companyId === selCompany.id;
			})
			_.forEach(storageLocations, (value) => {
				let loc = value;
				this.storageLocationsObj[loc.id] = {
					id: loc.id,
					name: loc.name,
					address: loc.address,
					geoaddress: LocationUtil.getGeoaddress(loc.geoaddress),
					areaRadius: loc.areaRadius ? loc.areaRadius : 100,
					isIncluded: this.getIsIncluded(loc.id),
					isActive: loc.isActive,
				};

				this.storageLocationItems.push(this.storageLocationsObj[loc.id]);
			});

			this.totalRows = _.size(this.storageLocationItems);
		},
		getIsIncluded(locId) {
			let locObj = _.find(this.form.storageLocations, o => {
				return o.id === locId;
			});
			return locObj && locObj.isIncluded;
		}
	}
};
</script>

<style scoped>
.connection-types {
	margin-top: -5px;
	margin-bottom: 10px;
}
</style>